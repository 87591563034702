export default {
  languages: {
    us: 'Inglés',
    es: 'Español'
    // mx: 'Español (México)',
    // co: 'Español (Colombia)',
    // cl: 'Español (Chile)',
    // pe: 'Español (Perú)',
    // ec: 'Español (Ecuador)'
  },
  routes: {
    properties: 'casas-y-apartamentos',
    projects: 'nuevos-proyectos',
    blog: 'blog',
    blogSearch: 'busqueda',
    category: 'c',
    categories: {
      present: 'actualidad',
      qualityOfLife: 'calidad-de-vida',
      investment: 'inversion',
      miamiTourism: 'turismo-en-miami'
    },
    institutional: 'pfs',
    calculator: 'calculadora-de-hipotecas',
    marketReport: 'reporte-de-mercado',
    county: 'condado',
    account: 'cuenta',
    profile: 'perfil',
    savedProperties: 'propiedades-guardadas',
    savedSearches: 'busquedas-guardadas',
    notifications: 'notificaciones',
    resetPassword: 'reiniciar-contrasena',
    elite: 'elite',
    eliteThankYou: 'gracias',
    eliteSocialLogin: 'social-login',
    test: 'pagina-de-prueba'
  },
  header: {
    signUp: 'Sign Up'
  },
  contactForm: {
    title: 'Invierte en Miami',
    description:
      'Déjanos tus datos y recibe asesoría personalizada de cómo invertir en\n      Miami'
  },
  menu: {
    home: 'Inicio',
    search: 'Buscar',
    developments: 'Desarrollos',
    services: 'Servicios',
    aboutUs: 'Quiénes Somos',
    blog: 'Blog',
    resources: 'Recursos',
    contactUs: 'Contáctanos',
    newProperties: 'Propiedades Nuevas',
    properties: 'Propiedades Usadas',
    buildings: 'Búsqueda en Edificios',
    zone: 'Búsqueda por Zona',
    inListing: 'Listado PFS',
    financing: 'Financiamiento',
    financingLink: '/pfs/prestamos-para-extranjeros',
    propertyManagement: 'Admin. de Propiedades',
    propertyManagementLink: '/pfs/administracion-de-propiedades',
    accounting: 'Contabilidad, seguros e inmigración',
    accountingLink: '/pfs/asesoria-contable-y-legal',
    insurance: 'Seguro',
    migration: 'Inmigración',
    rentInDollars: 'Renta en Dólares',
    rentInDollarsLink: '/pfs/renta-en-dolares',
    aboutUsLink: '/pfs/quienes-somos',
    pfs: 'PFS',
    institutional: 'Institucional',
    contactUsLink: '/pfs/contactanos'
  },
  common: {
    translatedVersion: 'Versión en inglés',
    searchResults: 'Resultados de Búsqueda',
    date: 'Fecha',
    mightInterestYou: 'Quizás pueda interesarte:',
    bestHousesForSale: 'Mejores casas en venta',
    cookiesMessage:
      'Usamos cookies para asegurar que te damos la mejor experiencia en nuestra web. Si continúas usando este sitio, asumiremos que estás de acuerdo con ello.',
    closeCookiesMessage: 'Cerrar mensaje de cookies',
    contactMenu: 'Contacto',
    accountMenu: 'Cuenta',
    signInSuccess: 'Sesión iniciada correctamente',
    signUpSuccess: 'Cuenta creada correctamente',
    eliteSignInError:
      'Debes ser miembro del Elite Club para acceder a esta página',
    accept: 'Aceptar',
    select: 'Seleccionar',
    on: 'Encendido',
    off: 'Apagado',
    done: 'Listo',
    goBack: 'Volver',
    goBackHome: ' Volver al Inicio',
    share: 'Compartir',
    delete: 'Eliminar',
    menu: 'Menú',
    autocomplete: 'Auto completar',
    close: 'Cerrar',
    closePopup: 'Cerrar ventana emergente',
    closeFilters: 'Cerrar filtros',
    noResults: 'No hay resultados',
    noOptionsAvailable: 'No hay opciones disponibles',
    range: '{0} a {1}',
    by: 'por {0}',
    fromN: 'Desde {0}',
    toN: 'Hasta {0}',
    photoOf: 'Foto de {0}',
    errors: {
      unknown: 'Error'
    },
    viewMore: 'Ver Más',
    viewLess: 'Ver Menos',
    oops: '¡Ups!',
    noItemsFound: 'No se encontraron resultados',
    pageNotFound: 'No hemos podido encontrar la página que buscas',
    tryAdjustingFilters: 'Intenta ajustar tu búsqueda',
    next: 'Siguiente',
    previous: 'Anterior',
    perMonthNumber: '/mes',
    link: 'Enlace',
    yes: 'Sí',
    no: 'No',
    avgPrice: 'Precio promedio',
    avgPriceSqft: 'Precio promedio / SQFT',
    infoDisclaimer:
      'La información de IDX se proporciona exclusivamente para uso personal y no comercial, y no se puede utilizar para ningún otro propósito que no sea identificar propiedades potenciales que los consumidores puedan estar interesados en comprar. La información se considera confiable pero no está garantizada. \nLa oferta de compensación del corredor de la lista se realiza solo a los participantes de la MLS donde se archiva la lista.'
  },
  elite: {
    menu: {
      launches: 'Beneficios',
      calendar: 'Agenda Miami'
    },
    auth: {
      login: 'Ingresar',
      profile: 'Usuario'
    },
    title: 'Elite Club - PFS Realty',
    description:
      'Conozca los beneficios de ser parte del Elite Club de PFS Realty',
    intro: `Invertir en Miami, es una excelente decision, haberlo hecho con <strong>PFS Realty Group</strong> es abrir las puertas de grandes oportunidades y beneficios.`,
    welcome: 'Bienvenido a PFS Élite Club',
    benefits:
      'Conozca algunos de sus <span class="font-bodoni">beneficios</span>',
    eventCalendar:
      'Eventos <span class="font-bodoni">recomendados</span> este mes en Miami',
    pfsEventCalendar:
      '<span class="font-bodoni">Próximos</span> eventos de PFS Realty Group',
    moreEvents:
      'Conozca la programación completa haciendo <link>Click aquí</link>',
    footer: 'Copyright© 2024 PFS Realty Group. Todos los derechos reservados.',
    thankYouPage: {
      title: 'Gracias',
      subTitle: 'Y nuevamente bienvenido a PFS Élite Club',
      info: 'Muy pronto podrá disfrutar todos sus beneficios.'
    },
    socialLoginPage: {
      title: 'Ingresa a PFS Élite Club con tus redes sociales'
    }
  },
  blog: {
    title: 'Oportunidades en Miami y el Sur de la Florida',
    description:
      'Oportunidades en Miami y en el Sur de La Florida Conoce las oportunidades de inversión, para vivir o disfrutar en Miami y el Sur de La Florida.',
    subtitle:
      'Conoce las oportunidades de inversión, para vivir o disfrutar en Miami y el Sur de la Florida',
    contentTable: 'Tabla de contenidos',
    nextPost: 'Siguiente',
    previousPost: 'Anterior',
    category: 'Categoría',
    categories: {
      present: 'Actualidad',
      qualityOfLife: 'Calidad de Vida',
      investment: 'Inversión',
      miamiTourism: 'Turismo en Miami'
    },
    categoryArticles: 'Artículos de la categoría',
    searchResults: 'Resultados de la búsqueda',
    searchHint: 'Afina la búsqueda para ver resultados en el blog',
    relatedPosts: 'Noticias relacionadas',
    shareText: 'Mira este post en PFS',
    shareLinkCopied: 'Enlace para compartir copiado al portapapeles',
    seo: {
      title: "Blog en Español {'|'} PFS Realty"
    }
  },
  auth: {
    title: 'Bienvenido a PFS',
    goToProfile: 'Ir a mi perfil',
    signIn: 'Ingresar',
    signOut: 'Cerrar sesión',
    signUp: 'Nueva cuenta',
    createPassword: 'Crea tu contraseña',
    createPasswordHelp:
      'Ingresa tu dirección de correo y te enviaremos un enlace para crear tu contraseña.',
    forgotPassword: 'Recuperar contraseña',
    forgotPasswordHelp:
      'Ingresa tu dirección de correo y te enviaremos un enlace para cambiar tu contraseña.',
    forgotPasswordSubmit: 'Enviar',
    forgotPasswordQuestion: '¿Olvidaste tu contraseña?',
    knowPasswordQuestion: '¿Sabe su contraseña?',
    forgotPasswordSuccess:
      'Se ha enviado un correo electrónico con instrucciones para cambiar su contraseña.',
    passwordReset: 'Cambiar contraseña',
    passwordResetSuccess: 'Tu contraseña ha sido cambiada correctamente.',
    passwordResetError: 'Este enlace ha expirado o es inválido.',
    passwordResetHelp: 'Ingresa tu nueva contraseña.',
    invalidTicketHelp: 'Solicita un nuevo enlace para cambiar tu contraseña.',
    email: 'Correo electrónico',
    emailNewAccount: 'Ingresa un correo electrónico',
    emailPlaceholder: 'Ingresa un correo electrónico',
    emailRequired: 'Ingresa tu correo electrónico',
    emailInvalid: 'Ingresa un correo electrónico válido',
    password: 'Contraseña',
    passwordNewAccount: 'Crea una contraseña',
    passwordPlaceholder: 'Ingresa tu contraseña',
    createPasswordPlaceholder: 'Crear una contraseña',
    passwordRequired: 'Ingresa una contraseña',
    passwordInvalid: 'Ingresa una contraseña válida',
    loginToSavePropertyHint:
      'Inicia sesión o crea una cuenta para guardar una propiedad',
    signInErrors: {
      '400': 'Revise su correo electrónico y/o contraseña',
      '401': 'Correo y/o contraseña inválidos',
      '403': 'Debe verificar su correo electrónico',
      '500': 'Error del servidor',
      unknown: 'Error'
    },
    signUpErrors: {
      '400': 'Revise su correo electrónico y/o contraseña',
      '403': 'Correo y/o contraseña inválidos',
      '409': 'Correo ya registrado',
      '500': 'Error del servidor',
      unknown: 'Error'
    },
    resetPasswordErrors: {
      '10': 'Correo electrónico inválido',
      '40': 'Usuario no encontrado',
      '400': 'Usuario no encontrado',
      '403': 'Correo electrónico inválido',
      '500': 'Error del servidor',
      unknown: 'Error'
    },
    setNewPasswordErrors: {
      '10': 'Correo electrónico inválido',
      '40': 'Usuario no encontrado',
      '400': 'Usuario no encontrado',
      '403': 'Correo electrónico inválido',
      '500': 'Error del servidor',
      unknown: 'Error'
    },
    submitSignIn: 'Ingresar',
    submitSignUp: 'Enviar',
    charactersAmountHint: 'Al menos 8 caracteres',
    characterMixHint: 'Mezcla de letras y números',
    specialCharacterHint: 'Al menos un carácter especial',
    signInDisclaimer:
      'Al presionar Enviar, aceptas los {0}términos y condiciones{1}.',
    orConnectWith: 'O conéctate con'
  },
  home: {
    seo: {
      title:
        "Inmobiliaria en Miami Florida {'|'} Bienes Raices - PFS Realty Group",
      description:
        'Asesoramos a nuestros clientes con la compra y venta de inmuebles en Miami Florida. Somos expertos en bienes raices en Miami y Miami Beach.'
    },
    hero: {
      title: 'Casas y Apartamentos en Venta y Renta en Miami',
      p: 'Propiedades',
      f: 'Financiamiento',
      s: 'Servicios'
    },
    used: 'Usado',
    new: 'Nuevo',
    rent: 'Rentar',
    buy: 'Usado',
    location: 'Ciudad, código postal o condado',
    currentLocation: 'Ubicación actual',
    contactFormTitle:
      '¡Contacta a uno de nuestros asesores para más información!',
    contactFormText:
      'Accede a nuestra asesoría gratuita de servicios 360° y obtén hasta un 70% de financiamiento en tu inversión.',
    properties_title: 'Nuestro portafolio de propiedades',
    propertiesPortfolio: 'Apartamentos y Casas en venta y renta en Miami',
    apartmentsPortfolioDescription: 'Nuestro portafolio de apartamentos',
    housesPortfolioDescription: 'Nuestro portafolio de casas',
    orlandoPortfolioDescription: 'Proyectos en Orlando',
    find_property_title: 'ENCUENTRA TU PROPIEDAD POR CIUDAD',
    find_property_text:
      'Tenemos un amplio portafolio de propiedades perfectas para ti.',
    clients: {
      title:
        '2.000 clientes en todo el mundo han invertido en Miami gracias a PFS Realty',
      features: {
        one: {
          title: '22 años de experiencia en ventas de propiedades en EE. UU.',
          description:
            'Más de dos décadas de experiencia en ventas de propiedades en EE. UU., brindando un servicio confiable y resultados exitosos.'
        },
        two: {
          title: '65 asesores en 12 países de América y Europa.',
          description:
            'Amplia red de 65 asesores estratégicamente ubicados en América y Europa para maximizar tus oportunidades de inversión inmobiliaria.'
        },
        three: {
          title: 'Más de 2.000 clientes satisfechos confían en nosotros',
          description:
            'Más de 2.000 clientes satisfechos respaldan nuestro compromiso con un servicio excepcional y resultados satisfactorios en inversiones inmobiliarias. Contáctanos y experimenta el éxito con PFS Realty.'
        }
      }
    },
    services: {
      title: 'Servicios integrales',
      properties: {
        title: 'Adquiere la propiedad ideal',
        description:
          'Compra de inmuebles nuevos y usados de alta calidad. Contamos con amplia experiencia y conocimiento para encontrar la propiedad ideal.'
      },
      financing: {
        title: 'Financiamiento a tu medida',
        description:
          'Ofrecemos préstamos en Estados Unidos para extranjeros flexibles y personalizados para extranjeros con mínimo 30% de entrada. Adaptamos las condiciones a tus necesidades.'
      },
      extra_income: {
        title: 'Genera ingresos extras alquilando',
        description:
          'Alquiler de tu propiedad con inquilinos que generan ingresos a través de la renta en dólares. Gestionamos integralmente para maximizar la rentabilidad.'
      },
      management: {
        title: 'Administración de propiedades',
        description:
          'Cuidado profesional, mantenimiento preventivo y correctivo, administración eficiente de tu inversión inmobiliaria. Optimizamos costos y beneficios.'
      },
      legal: {
        title: 'Asesoría fiscal y contable integral',
        description:
          'Equipo interdisciplinario de profesionales altamente especializados en todos los aspectos legales y contables. Aseguramos el cumplimiento normativo.'
      }
    },
    events: {
      title: 'Próximos eventos',
      formMessage: 'Déjanos tus datos para separar un cupo en nuestro evento',
      registerButton: 'Reserva Entrada'
    },
    testimonialsTitle: 'Testimoniales',
    news_title: 'Nuestro Blog',
    popupFormTitle:
      'Suscríbete a nuestro newsletter y obtén información de primera mano sobre el mercado inmobiliario en Miami y Orlando.',
    popupFormMessage:
      'Consejos exclusivos, tendencias del mercado y posibilidades únicas. ¡No te pierdas esta oportunidad para impulsar tu éxito en bienes raíces!'
  },
  account: {
    accountSettings: 'Configuración de cuenta',
    profile: 'Perfil',
    profileHint:
      'Personalice su cuenta y actualice sus preferencias de ingreso',
    notifications: 'Notificaciones',
    notificationsSectionHint:
      'Administre sus notificaciones y preferencias de correo electrónico',
    notificationsHint: 'Encender o apagar las notificaciones de su cuenta',
    searchNotificationsHint: 'Encender las notificaciones para esta búsqueda',
    enableAllNotifications: 'Habilitar todas las notificaciones',
    propertyNotificationsEnabled:
      'Notificaciones habilitadas para esta propiedad',
    propertyNotificationsDisabled:
      'Notificaciones deshabilitadas para esta propiedad',
    savedProperties: 'Propiedades Salvadas',
    savedPropertiesHint:
      'Mantenga un registro de las casas que le gustan y las que ha visto recientemente.',
    toggleNotificationsHint:
      'Use el interruptor para encender o apagar las notificaciones',
    savedSearches: 'Búsquedas Salvadas',
    recentlyViewedProperties: 'Vistas Recientemente',
    personalInfo: 'Información personal',
    name: 'Nombre',
    namePlaceholder: 'Ingresa tu nombre',
    lastName: 'Apellido',
    lastNamePlaceholder: 'Ingresa tu apellido',
    picture: 'Foto',
    pictureHint: 'Suba una foto de perfil',
    accessAndSecurity: 'Acceso y seguridad',
    email: 'Correo electrónico',
    emailPlaceholder: 'Ingresa tu correo electrónico',
    password: 'Contraseña',
    passwordPlaceholder: 'Ingresa tu contraseña',
    noSavedProperties: 'Obten actualizaciones de tus propiedades salvadas',
    searchProperties: 'Buscar casas',
    untitledSavedSearch: 'Búsqueda sin título',
    noSavedSearches: 'Obten actualizaciones de tus busquedas salvadas',
    savedSearchesHint:
      'Salvar tus busquedas te ahorra tiempo y te dejara saber de las nuevas actualizaciones.',
    changePasswordEmailWithButton:
      'Cambie su contraseña a través de su correo electrónico {button}.',
    changePasswordEmailButton: 'haciendo clic aquí',
    changePasswordEmailSent:
      'Se ha enviado un correo electrónico a {email} con instrucciones para cambiar su contraseña.'
  },
  search: {
    previous: 'Anterior',
    next: 'Proximo',
    title: 'Búsqueda de casas, apartamentos y otros inmuebles',
    searchPlaceholder:
      'Ciudad, condado, código postal, vecindario o nuevo proyecto',
    searchLabel: 'Buscar',
    nResults: '{0} resultados',
    nActiveProperties: '{0} activos',
    resetFilters: 'Borrar filtros',
    resetFiltersLong: 'Remover todos los filtros',
    acceptFilters: 'Aceptar',
    saveSearch: 'Salvar búsqueda',
    searchSaved: 'Búsqueda salvada',
    map: 'Mapa',
    listView: 'Lista',
    county: 'Condado',
    city: 'Ciudad',
    zipcode: 'Código postal',
    neighborhood: 'Vecindario',
    allCounties: 'Todos los condados',
    allCities: 'Todas las ciudades',
    allZipcodes: 'Todos los códigos postales',
    allNeighborhoods: 'Todos los vecindarios',
    from: 'Desde',
    to: 'Hasta',
    noMin: 'Sin min.',
    noMax: 'Sin max.',
    bedroomsCard: 'habitación | habitaciones',
    bathsFullCard: 'baño | baños',
    bedroomsShort: 'hab.',
    bathsFullShort: 'ba.',
    areaShort: 'sqft',
    areaOf: 'área de',
    postalCodeShort: 'Cod. Postal {0}',
    filters: 'Filtros',
    propertyType: 'Tipo de inmueble',
    propertyTypes: 'Tipos de propiedad',
    timeRange: 'Rango de Tiempo',
    lastNMonths: 'Últimos {0} meses',
    selectPropertyType: 'Propiedades',
    selectHouseType: 'Tipos',
    selectCondoType: 'Tipos',
    selectPropertySubTypes: 'Tipos',
    property: 'Propiedad',
    properties: 'Casas y apartamentos',
    propertiesAlt: 'Propiedades',
    projects: 'Proyectos',
    houseType: 'Casa',
    housesType: 'Casas',
    condoType: 'Apartamento',
    condosType: 'Apartamentos',
    apartmentsCondoType: 'Condominio de apartamentos',
    housesCondoType: 'Condominio de casas',
    apartmentsCondosType: 'Condominios de apartamentos',
    housesCondosType: 'Condominios de casas',
    house: 'Casa',
    houses: 'Casas',
    singleFamilyResidence: 'Single Family',
    singleFamilyResidences: 'Single Families',
    townhouse: 'Townhouse',
    townhouses: 'Townhouses',
    villa: 'Villa',
    villas: 'Villas',
    condominium: 'Condo',
    condominiums: 'Condos',
    apartment: 'Apartamento',
    apartments: 'Apartamentos',
    multiFamily: 'Multi Family',
    multiFamilies: 'Multi Families',
    building: 'Edificio',
    buildings: 'En Edificios',
    price: 'Precio',
    priceFrom: 'Precio desde',
    priceTo: 'Precio hasta',
    priceFromShort: 'Precio min.',
    priceToShort: 'Precio max.',
    areas: 'áreas',
    bedrooms: 'Habitaciones',
    nOrMoreBedrooms: '{0} o más habitaciones',
    nOrLessBedrooms: 'hasta {0} habitaciones',
    nBedrooms: '{0} habitaciones',
    nRooms: '{0} cuartos',
    moreOptions: 'Más opciones',
    showPlaces: 'Mostrar lugares',
    bedroomsAndBathrooms: 'Hab. y baños',
    bathrooms: 'Baños',
    nOrMoreBathrooms: '{0} o más baños',
    nOrLessBathrooms: 'hasta {0} baños',
    nBathrooms: '{0} baños',
    nArea: '{0} pies cuadrados',
    nAreaShort: '{0}',
    nAreaM2: '{0} m²',
    nStories: '{0} pisos',
    nYear: 'el {0}',
    area: 'Área',
    areaFrom: 'Área desde',
    areaTo: 'Área hasta',
    year: 'Año',
    yearFrom: 'Año desde',
    yearTo: 'Año hasta',
    furnished: 'Amoblado',
    view: 'Vista',
    viewOptions: {
      any: 'Cualquiera',
      ocean: 'Al agua'
    },
    condoCost: 'Costo del condominio',
    condoCostFrom: 'Costo del condominio desde',
    condoCostTo: 'Costo del condominio hasta',
    hopa: 'Comunidad de +55',
    dock: 'Muelle',
    shortTermLease: 'Renta corta',
    inListing: 'En el listado de PFS',
    inListingItems: '{0} en el listado de PFS',
    pendingAndUnderContract: 'Pendientes y Bajo contrato',
    pendingAndUnderContractProperties: 'Propiedades pendientes y bajo contrato',
    advancedSearch: 'Búsqueda avanzada',
    viewMap: 'Ver mapa',
    viewSatellite: 'Ver satélite',
    viewBuildings: 'Ver edificios',
    view2D: '2D',
    view3D: '3D',
    sortBy: 'Ordenar',
    visitSite: 'Ver sitio web',
    mapLayers: {
      newProjects: 'Nuevos proyectos',
      beaches: 'Playas',
      malls: 'Centros comerciales',
      restaurants: 'Restaurantes',
      collegeUniversities: 'Universidades',
      publicSchools: 'Escuelas públicas',
      privateSchools: 'Escuelas privadas',
      hospitals: 'Hospitales'
    },
    sortByOptions: {
      mostRecent: 'Más reciente',
      oldest: 'Más antigua',
      highestPrice: 'Mayor precio',
      lowestPrice: 'Menor precio',
      fewestBathrooms: 'Menor cant. baños',
      mostBathrooms: 'Mayor cant. baños',
      fewestBedrooms: 'Menor cant. habitaciones',
      mostBedrooms: 'Mayor cant. habitaciones',
      smallestArea: 'Menor área',
      largestArea: 'Mayor área',
      firstToFinish: 'Primeros en finalizar',
      lastToFinish: 'Últimos en finalizar',
      counties: 'Por condado',
      cities: 'Por ciudad'
    },
    buyTypeOptions: {
      used: 'Usado | Usados',
      new: 'Nuevo | Nuevos',
      rent: 'Rentar',
      buy: 'Comprar',
      sold: 'Vendido | Vendidos',
      rented: 'Rentado | Rentados'
    },
    hopaOptions: {
      any: 'Cualquiera',
      'hopa-eligible': 'Apto para HOPA',
      'no-hopa': 'Sin HOPA'
    },
    findItems: 'Encuentra {items}',
    availableItems: '{items} en venta y renta',
    soldItems: '{items} vendidos',
    soldItems_female: '{items} vendidas',
    rentedItems: '{items} rentados',
    rentedItems_female: '{items} rentadas',
    soldOrRentedItems: '{items} vendidos o rentados',
    soldOrRentedItems_female: '{items} vendidas o rentadas',
    newItems: '{items} nuevos en venta',
    newItems_female: '{items} nuevas en venta',
    usedItems: '{items} usados en venta',
    usedItems_female: '{items} usadas en venta',
    itemsForSale: '{items} en venta',
    itemsForRent: '{items} en renta',
    rentSearchResults: '{items} en renta en {search}',
    buySearchResults: '{items} en venta en {search}',
    soldSearchResults: '{items} vendidas en {search}',
    rentedSearchResults: '{items} rentadas en {search}',
    buyRentSearchResults: '{items} en venta y renta en {search}',
    rentSearchResultsNearYou: '{0} en renta cerca de tu ubicación',
    buySearchResultsNearYou: '{0} en venta cerca de tu ubicación',
    soldSearchResultsNearYou: '{0} vendidas cerca de tu ubicación',
    rentedSearchResultsNearYou: '{0} rentadas cerca de tu ubicación',
    buyRentSearchResultsNearYou: '{0} en venta y renta cerca de tu ubicación',
    rentResults: 'Rentas',
    soldResults: 'Propiedades vendidas',
    rentedResults: 'Propiedades rentadas',
    buyResults: 'Propiedades en venta',
    anyResults: 'Propiedades disponibles',
    all: 'Todos',
    otherItems: 'Propiedades cercanas',
    otherSearches: 'Otras personas también buscaron',
    removeBoundary: 'Quitar límites',
    addBoundary: 'Añadir límite',
    cancelBoundaryDraw: 'Cancelar',
    otherCities: 'Otras ciudades',
    sortByCity: 'Ciudades',
    tableSortOptions: {
      unitNumberAsc: 'Unidad # menor a mayor',
      unitNumberDesc: 'Unidad # mayor a menor',
      priceLowHigh: 'Precio menor a mayor',
      priceHighLow: 'Precio mayor a menor',
      bedroomsLowHigh: 'Habitaciones menor a mayor',
      bedroomsHighLow: 'Habitaciones mayor a menor',
      bathroomsLowHigh: 'Baños menor a mayor',
      bathroomsHighLow: 'Baños mayor a menor',
      areaLowHigh: 'Área menor a mayor',
      areaHighLow: 'Área mayor a menor',
      dateNewOld: 'Más reciente a más antiguo',
      dateOldNew: 'Más antiguo a más reciente'
    }
  },
  paramsKeys: {
    propertyType: 'pt',
    propertySubType: 'ps',
    projectType: 'py',
    price: 'pr',
    bedrooms: 'h',
    bathsFull: 'b',
    area: 'a',
    year: 'y',
    furnished: 'am',
    view: 'v',
    condoCost: 'cc',
    dock: 'd',
    shortTermLease: 'rc',
    excludePendingAndUnderContract: 'epc',
    inListing: 'el',
    sortBy: 'o',
    buyType: 'bt',
    page: 'pg',
    location: 'l',
    county: 'co',
    city: 'ci',
    zipcode: 'cp',
    neighborhood: 'n',
    geolocation: 'g',
    usedProject: 'up',
    hopa: 'ho'
  },
  legacyParamsKeys: {
    propertyType: 'tipo',
    propertySubType: 'propiedades',
    projectType: 'proyectos',
    price: 'precio',
    bedrooms: 'habitaciones',
    bathsFull: 'banos',
    area: 'area',
    year: 'fecha',
    furnished: 'amoblado',
    view: 'vista',
    condoCost: 'costo-condominio',
    dock: 'muelle',
    shortTermLease: 'renta-corta',
    excludePendingAndUnderContract: 'excluir-pendientes-y-bajo-contrato',
    inListing: 'en-listado',
    sortBy: 'orden',
    buyType: 'tipo',
    page: 'pagina',
    location: 'busqueda',
    county: 'condado',
    city: 'ciudad',
    zipcode: 'codigo-postal',
    neighborhood: 'vecindario',
    geolocation: 'gl',
    usedProject: 'usado',
    hopa: 'hopa'
  },
  paramsAliases: {
    RES: 'comprar',
    RNT: 'rentar',
    SOL: 'vendidos',
    SOL_alt: 'vendido',
    RTD: 'rentados',
    RTD_alt: 'rentado',
    house: 'casas',
    singleFamilyResidence: 'single-families',
    townhouse: 'townhouses',
    villa: 'villas',
    condo: 'condos',
    apartment: 'apartamentos',
    building: 'en-edificios',
    multiFamily: 'multi-families',
    'pre-construction': 'preconstruccion',
    'under-construction': 'en-construccion',
    built: 'construido',
    'on-the-market': 'en-el-mercado',
    'hopa-eligible': 'hopa',
    'no-hopa': 'no-hopa',
    'yearBuilt:desc': 'mas-nuevos',
    'yearBuilt:asc': 'mas-antiguos',
    'listPrice:desc': 'mayor-precio',
    'listPrice:asc': 'menor-precio',
    'bathsFull:desc': 'mas-banos',
    'bathsFull:asc': 'menos-banos',
    'bedrooms:desc': 'mas-habitaciones',
    'bedrooms:asc': 'menos-habitaciones',
    'propertyArea:desc': 'mayor-area',
    'propertyArea:asc': 'menor-area',
    'yearCompletion:desc': 'ultimos-en-finalizar',
    'yearCompletion:asc': 'primeros-en-finalizar',
    buildingsInCities: 'por-ciudad',
    city: 'ciudades',
    availability: 'disponibilidad',
    pending: 'pendiente',
    closed: 'cerrado',
    statistics: 'estadisticas'
  },
  locationTypes: {
    county: 'Condado',
    city: 'Ciudad',
    zip_code: 'Código postal',
    project: 'Proyecto',
    building: 'Edificio',
    address: 'Dirección',
    neighborhood: 'Vecindario',
    mls_id: 'MLS ID'
  },
  property: {
    save: 'Salvar',
    saved: 'Salvado',
    propertySaved: 'Propiedad salvada en favoritos',
    propertyRemoved: 'Propiedad removida de favoritos',
    backToSearch: 'Volver a Búsqueda',
    backToProject: 'Volver al edificio',
    share: 'Compartir',
    copyShareLink: 'Copiar enlace para compartir',
    previousProperty: 'Anterior',
    nextProperty: 'Siguiente',
    bedrooms: 'Habitaciones',
    bathrooms: 'Baños',
    factsAndFeatures: 'Características',
    interior: 'Interior',
    bedroomsAndBathrooms: 'Dormitorios y Baños',
    bedroomsLong: 'Dormitorios',
    bathroomsLong: 'Baños',
    fullBathrooms: 'Baños completos',
    flooring: 'Pisos',
    heating: 'Calefacción',
    heatingFeatures: 'Características de calefacción',
    cooling: 'Enfriamiento',
    hasCooling: 'Tiene enfriamiento',
    coolingFeatures: 'Características de enfriamiento',
    appliances: 'Electrodomésticos',
    appliancesIncluded: 'Electrodomésticos incluidos',
    interiorFeatures: 'Características interiores',
    otherInteriorFeatures: 'Otras características interiores',
    virtualTour: 'Tour virtual',
    property: 'Propiedad',
    parking: 'Estacionamiento',
    parkingFeatures: 'Características de estacionamiento',
    poolFeatures: 'Características de la piscina',
    pool: 'Piscina',
    privatePool: 'Piscina privada',
    exteriorFeatures: 'Características exteriores',
    patioAndPorchFeatures: 'Detalles del patio y el porche',
    viewDescription: 'Descripción de la vista',
    otherPropertyInformation: 'Otra información de la propiedad',
    parcelNumber: 'Número de parcela',
    attachedToAnotherStructure: 'Adjunto a otra estructura',
    otherEquipment: 'Otros equipos',
    construction: 'Construcción',
    typeAndStyle: 'Tipo y estilo',
    homeType: 'Tipo de hogar',
    materialInformation: 'Información del material',
    constructionMaterials: 'Materiales de construcción',
    condition: 'Condición',
    propertyCondition: 'Condición de la propiedad',
    newConstruction: 'Construcción nueva',
    type: 'Tipo',
    yearBuilt: 'Año de construcción',
    communityAndNeighborhood: 'Comunidad y vecindario',
    security: 'Seguridad',
    location: 'Ubicación',
    region: 'Región',
    neighborhood: 'Vecindario',
    hoaAndFinancial: 'HOA y financiamiento',
    hoa: 'HOA',
    hasHoa: 'Tiene HOA',
    hoaFee: 'Cuota de HOA',
    amenitiesIncluded: 'Servicios incluidos',
    otherFinancialInformation: 'Otra información financiera',
    buyerAgencyCompensation: 'Compensación de la agencia del comprador',
    contactAgent: 'Contactar agente',
    requestShowing: 'Solicitar visita',
    similarProperties: 'Propiedades relacionadas',
    shareTitle: 'Propiedad en PFS',
    shareText: 'Mira esta propiedad en PFS',
    shareLinkCopied: 'Enlace para compartir copiado al portapapeles',
    areaSqft: 'Área (sqft.)',
    mlsCode: 'MLS #',
    sold: 'Vendido',
    sold_female: 'Vendida',
    rented: 'Rentado',
    rented_female: 'Rentada',
    active: 'Activo',
    active_female: 'Activa',
    estimatedPayment: 'Pago estimado',
    getPrequalification: 'Calcula tus pagos mensuales'
  },
  project: {
    newProjects: 'Nuevos proyectos',
    usedBuildings: 'Edificios usados',
    project: 'Proyecto',
    priceSQFT: 'Precio por pie cuadrado',
    priceSQFTShort: '$/Pie²',
    SQFT: 'Área',
    SQFTShort: 'Pies²',
    preConstruction: 'Preconstrucción',
    underConstruction: 'En construcción',
    built: 'Construido',
    onTheMarket: 'En el mercado',
    profile: 'Perfil',
    availability: 'Disponibilidad',
    pending: 'Pendiente',
    closed: 'Cerrado',
    stats: 'Estadísticas',
    projectDetails: 'Detalles',
    propertyDetails: 'Detalles de la propiedad',
    floorPlans: 'Planos de planta',
    features: 'Características',
    amenities: 'Amenidades',
    services: 'Servicios',
    exteriorAspects: 'Aspectos exteriores',
    propertyDescriptions: 'Descripciones de la propiedad y de la zona',
    location: 'Indicaciones de ubicación',
    layout: 'Distribución',
    salesPolicies: 'Políticas de venta',
    financing: 'Financiamiento',
    deliveryStages: 'Etapas de entrega',
    appliance: 'Electrodomesticos',
    heating: 'Calefacción',
    maintenanceFee: 'Cuota de mantenimiento',
    construction: 'Construcción',
    garage: 'Garage',
    restrictions: 'Restricciones',
    exterior: 'Exterior',
    parking: 'Estacionamiento',
    termsConsidered: 'Términos considerados',
    interior: 'Interior',
    petsAllowed: 'Mascotas permitidas',
    flooring: 'Pisos',
    furnished: 'Amoblado',
    HOAFee: 'Cuota de mantenimiento/cuarto',
    HOPA: 'HOPA',
    hopaEligible: 'Apto',
    noHopa: 'Sin HOPA',
    cooling: 'Aire acondicionado',
    yearBuilt: 'Año de construcción',
    similarProjects: 'Proyectos relacionados',
    yearCompletionShort: 'Est. completado',
    stories: 'Pisos',
    residences: 'Residencias',
    shareTitle: 'Proyecto en PFS',
    shareText: 'Mira este proyecto en PFS',
    shareLinkCopied: 'Enlace para compartir copiado al portapapeles',
    areaSqft: 'Área (sqft.)',
    sales: 'Ventas',
    rentals: 'Rentas',
    unitNumberShort: '# unidad',
    listPrice: 'Precio de lista',
    maintenanceExpenseShort: 'Mantenimiento ($)',
    listDate: 'Fecha de lista',
    closedDate: 'Fecha de cierre',
    availableCondosForRent: 'Apartamentos disponibles para rentar',
    pendingCondosForRent: 'Apartamentos pendientes para rentar',
    closedCondosForRent: 'Apartamentos rentados',
    availableCondosForSale: 'Apartamentos disponibles para la venta',
    pendingCondosForSale: 'Apartamentos pendientes para la venta',
    closedCondosForSale: 'Apartamentos vendidos',
    noCondos:
      '{project} no tiene condominios disponibles con las opciones seleccionadas',
    condos:
      '{project} tiene {n} apartamento(s) con las opciones seleccionadas con un precio por pie cuadrado promedio de {price}',
    statsLabels: {
      averageListPricePerSQFT: 'Precio promedio de lista / SQFT',
      minListPricePerSQFT: 'Precio mínimo de lista / SQFT',
      maxListPricePerSQFT: 'Precio máximo de lista / SQFT',
      monthsOfInventory: 'Meses de inventario',
      unitsSoldPast3Months: 'Unidades vendidas en los últimos 3 meses',
      unitsSoldPast6Months: 'Unidades vendidas en los últimos 6 meses',
      unitsSoldPast12Months: 'Unidades vendidas en los últimos 12 meses',
      unitsSoldPast24Months: 'Unidades vendidas en los últimos 24 meses',
      availableUnitsForSale: 'Unidades disponibles para la venta',
      TotalUnitsAvailableForSaleP:
        '% de unidades totales disponibles para la venta',
      minSalesPricePerSQFT_6Months: 'Precio mínimo de venta / SQFT (6 meses)',
      maxSalesPricePerSQFT_6Months: 'Precio máximo de venta / SQFT (6 meses)',
      averageSalesPricePerSQFT_3Months:
        'Precio promedio de venta / SQFT (3 meses)',
      averageSalesPricePerSQFT_6Months:
        'Precio promedio de venta / SQFT (6 meses)',
      averageSalesPricePerSQFT_12Months:
        'Precio promedio de venta / SQFT (12 meses)',
      averageSalesPricePerSQFT_24Months:
        'Precio promedio de venta / SQFT (24 meses)',
      availableUnitsForRent: 'Unidades disponibles para alquilar',
      totalUnitsAvailableForRentP:
        '% de unidades totales disponibles para alquilar',
      unitsRentedPast3Months: 'Unidades alquiladas en los últimos 3 meses',
      unitsRentedPast6Months: 'Unidades alquiladas en los últimos 6 meses',
      unitsRentedPast12Months: 'Unidades alquiladas en los últimos 12 meses',
      unitsRentedPast24Months: 'Unidades alquiladas en los últimos 24 meses',
      avgSalesPriceSqft: 'Precio promedio de venta / SQFT',
      avgRentalsPriceSqft: 'Precio promedio de alquiler / SQFT',
      year: 'Año',
      soldUnits: 'Unidades vendidas',
      rentedUnits: 'Unidades alquiladas',
      nSoldUnits: '{0} unidades vendidas',
      informationReliability:
        'Información considerada confiable pero no garantizada.'
    }
  },
  calculator: {
    title: 'Calculadora de Hipotecas',
    description:
      'Esta herramienta te permite simular el pago mensual de un préstamo hipotecario, considerando factores como el capital e interés, el predial, la comisión por administración y el seguro mensual. \n' +
      'Al ingresar los datos sobre el precio del inmueble, el enganche y la tasa de interés, puedes obtener un cálculo preciso de tus cuotas mensuales, facilitando la compra y financiamiento de tu vivienda a través de una institución financiera. \n',
    hint: 'Esto es esencial para tu educación financiera y la planificación de la compra de tu vivienda propia, permitiéndote gestionar mejor tu historial crediticio y ahorros.',
    monthlyPayments: 'Pagos Mensuales',
    principalInterest: 'Principal e intereses',
    propertyTax: 'Predial',
    associationFee: 'Cuota de asociación',
    principalInterestLegend: 'Principal e intereses',
    propertyTaxLegend: 'Predial',
    associationFeeLegend: 'Cuota de asociación',
    insuranceFee: 'Cuota de Seguro',
    homePrice: 'Precio',
    propertyTaxYearly: 'Impuesto Predial (anual)',
    associationFeeMonthly: 'Cuota de Asociación (mensual)',
    insuranceFeeMonthly: 'Cuota de Seguro (mensual)',
    downPayment: 'Pago Inicial (%)',
    interestRate: 'Tasa de Interés (%)',
    interestRatePlaceholder: 'Tasa de Interés',
    loanType: 'Tipo de Préstamo',
    loanType10Year: 'Fijo a 10 Años',
    loanType15Year: 'Fijo a 15 Años',
    loanType20Year: 'Fijo a 20 Años',
    loanType30Year: 'Fijo a 30 Años',
    disclaimer: '*Los cálculos son un estimado.'
  },
  footer: {
    navigationLinks: 'Enlaces de navegación',
    city_label: 'Ciudad',
    zip_code_label: 'Código postal',
    county_label: 'Condado',
    pre_construction_label: 'Preconstrucción',
    home_type_label: 'Tipo de propiedad',
    price_label: 'Precio de Venta',
    rent_label: 'Precio de Renta',
    other_cities: 'Otras ciudades',
    other_zipcodes: 'Otros códigos',
    other_types: 'Otros tipos',
    other_counties: 'Otros condados',
    other_preconstructions: 'Otras preconstrucciones',
    other_prices: 'Otros precios',
    findUs: 'Encuéntranos:',
    salesTitle: 'Representantes en:',
    countries:
      'MÉXICO • COLOMBIA • ARGENTINA • ESPAÑA • VENEZUELA • ECUADOR • CHILE\n            • PERÚ • CANADÁ • PORTUGAL',
    interest: 'De interés',
    termsAndConditions: 'Términos y condiciones',
    termsAndConditionsLink: '/pfs/terminos-y-condiciones',
    whoWeAre: 'Quiénes somos',
    allRightsReserved: 'Todos los derechos reservados'
  },
  connectors: {
    in: '{0} en {1}',
    with: '{0} con {1}',
    without: '{0} sin {1}',
    from: '{0} desde {1}',
    to: '{0} hasta {1}',
    and: '{0} y {1}',
    or: '{0} o {1}',
    of: '{0} de {1}',
    ranging: '{0} {1}',
    ofType: '{0} de tipo {1}',
    ofProperty: '{0} de {1}',
    type: '{0} tipo {1}',
    locatedIn: '{0} ubicado en {1}',
    locatedIn_female: '{0} ubicada en {1}',
    locatedIn_plural: '{0} ubicados en {1}',
    locatedIn_female_plural: '{0} ubicadas en {1}',
    builtIn: '{0} construido en {1}',
    builtIn_female: '{0} construida en {1}',
    default: '{0} {1}',
    withView: '{0} con vista {1}',
    withViewWith: '{0} con vista con {1}',
    withViewTo: '{0} con vista a {1}',
    withViewToThe: '{0} con vista al {1}',
    withViewToThe_female: '{0} con vista a la {1}',
    hasFeatures: '{0}, cuenta con {1}',
    withViewToThe_female_plural: '{0} con vista a las {1}',
    upTo: 'hasta {0}',
    recently: '{0} recientemente'
  },
  institutional: {
    seoTitle: 'PFS Realty Group',
    title: 'Oportunidades en Miami y en el Sur de La Florida',
    description:
      'Conoce las oportunidades de inversión, para vivir o disfrutar en Miami y el Sur de La Florida.'
  },
  marketReport: {
    title: 'Reporte de Mercado',
    description:
      'Conoce el estado actual del mercado inmobiliario en Miami y el Sur de La Florida.',
    longTitle:
      'Reporte del Mercado Inmobiliario en el Sur de La Florida - PFS Realty Group',
    help: 'Seleccione la zona, el tipo de propiedad y el resto de las opciones para ver la información actualizada',
    activeListings: 'Listados activos',
    nActiveListingsIn: '{count} Propiedades Activas en {location}',
    nActiveListings: '{0} Propiedades Activas en todos los condados',
    recentlySold: 'Recientemente vendidos',
    recentlyRented: 'Recientemente rentados',
    recentlyClosed: 'Recientemente cerrados',
    priceTrends: 'Tendencias de precios',
    averagePrice: 'Precios promedio en {0}',
    buyTypes: {
      sales: 'Ventas',
      rentals: 'Rentas'
    }
  },
  pages: {
    neighborhoodsIn: 'Vecindarios en {0}',
    citiesIn: 'Ciudades en {0}',
    counties: 'Condados'
  },
  seo: {
    locale: 'es',
    alternateLocale: 'us',
    defaultTitle: 'PFS Realty Group',
    defaultDescription:
      'En PFS Realty encuentra bienes raices e inmuebles en venta y renta en la Florida.',
    home: 'Inicio',
    defaultSingle: 'Casa o apartamento',
    singleSale: '{0} en venta',
    singleRent: '{0} en renta',
    singleSaleAlt: '{0} a la venta',
    singleRentAlt: '{0} a la renta',
    defaultMultiple: 'Casas y apartamentos',
    multipleSale: 'Casas y apartamentos en venta',
    multipleRent: 'Casas y apartamentos en renta',
    project: 'Proyecto',
    projects: 'Proyectos',
    preConstruction: '{0} en preconstrucción',
    underConstruction: '{0} en construcción',
    built: '{0} recientes',
    onTheMarket: '{0} en el mercado',
    complementaryTitle:
      'en PFSRealty encuentra bienes raices e inmuebles en venta y renta.',
    complementaryDescription:
      ', con PFS Realty Group. Descubre también oportunidades de inversión en Miami. ¡Contáctanos!',
    atFlorida: 'en la Florida',
    numberArticle: 'los {0}',
    numberArticleSingle: '{0}',
    yearBuilt: 'Construido en {0}',
    yearCompletion: 'Est. completado {0}',
    nResidences: '{0} residencias',
    exclusive: '{0} exclusivo',
    exclusive_female: '{0} exclusiva',
    mlsId: '#MLS {0}',
    hasPhotos: 'PFS Realty Group tiene {1} fotos de este {0}',
    hasPhotos_female: 'PFS Realty Group tiene {1} fotos de esta {0}',
    forFamily: '{0} para la comodidad de tu familia',
    counties: {
      longTitle: 'Condados en la Florida - PFS Realty Group',
      title: 'Condados en la Florida',
      description:
        'Encuentra propiedades en venta y renta en los condados de la Florida.'
    },
    cities: {
      longTitle: 'Ciudades en {0} - PFS Realty Group',
      title: 'Ciudades en {0}',
      description:
        'Encuentra propiedades en venta y renta en las ciudades de {0}, Florida.'
    },
    neighborhoods: {
      longTitle: 'Vecindarios en {0} - PFS Realty Group',
      title: 'Vecindarios en {0}',
      description:
        'Encuentra propiedades en venta y renta en los vecindarios de {0}, Florida.'
    },
    calculator: {
      longTitle: 'Calculadora de Hipotecas - PFS Realty Group',
      description:
        'Calcula fácilmente tus pagos hipotecarios para propiedades en Florida con nuestra herramienta online.'
    },
    discover: 'Descubre los {0}',
    discover_female: 'Descubre las {0}'
  }
};
