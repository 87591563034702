import { fixImageUrl } from '@/helpers/fixes';
import { propertySubTypeOptions } from '@/helpers/search';
import {
  getProjectLink,
  getPropertyLink,
  getUsedProjectLink
} from '@/helpers/sitemaps';
import { ExternalPromise } from '@/types/common';
import type {
  ProjectCardProps,
  ProjectDocumentResponse,
  PropertyCardProps,
  PropertyDocumentResponse
} from '@/types/properties';

export default function () {
  const {
    public: { cloudflareImageProcessing: cfUrl }
  } = useRuntimeConfig();

  const { t } = useNuxtApp().$i18n;

  function tl(key: string, locale: string = 'us') {
    return t(key, null, { locale });
  }

  const user = inject('user') as Ref<{ email?: unknown } | null>;

  const isAuthenticated = computed(() => {
    return Boolean(user?.value?.email);
  });

  const subTypeToTypeMap = computed(() => {
    return propertySubTypeOptions.reduce((acc, cur) => {
      acc[cur.value] = t(cur.typeText);
      return acc;
    }, {} as Record<string, string>);
  });

  const subTypeMap = computed(() => {
    return propertySubTypeOptions.reduce((acc, cur) => {
      acc[cur.value] = t(cur.text);
      return acc;
    }, {} as Record<string, string>);
  });

  type ResultType<T> = T extends PropertyDocumentResponse
    ? PropertyCardProps
    : ProjectCardProps;

  function formatProperty(
    raw: PropertyDocumentResponse | ProjectDocumentResponse,
    resultIndex: number = -1
  ) {
    if ('projectName' in raw) {
      const imgAlt = raw.address || '';
      const rawImages = raw.photos?.map(fixImageUrl) || [];
      return {
        id: raw.id,
        projectStatus: raw.projectStatus,
        imgAlt,
        images:
          rawImages?.map(url => {
            return {
              url: `${cfUrl}/image?w=500&f=webp&image=${url}`,
              set: `${cfUrl}/image?w=1000&f=webp&image=${url} 2x`,
              alt: imgAlt
            };
          }) || [],
        rawImages,
        name: raw.projectName,
        location: raw.county,
        city: raw.city,
        yearCompletion: raw.yearCompletion,
        minPrice: raw.minPrice,
        resultIndex,
        to:
          raw.projectStatus === 'on-the-market'
            ? `/${t('routes.properties')}/` + getUsedProjectLink(raw, t)
            : `/${t('routes.projects')}/` + getProjectLink(raw, t),
        type: raw.subType
      } as ProjectCardProps;
    } else if ('mlsId' in raw) {
      const property =
        subTypeToTypeMap.value[raw.subType] !== subTypeMap.value[raw.subType]
          ? t('connectors.type', [
              subTypeToTypeMap.value[raw.subType],
              subTypeMap.value[raw.subType]
            ])
          : subTypeMap.value[raw.subType];

      const address = t('connectors.in', [
        property,
        [raw.streetName, [raw.city, raw.state].filter(v => v).join(' ')]
          .filter(v => v)
          .join(', ')
      ]);

      const imgAlt = raw.full || '';

      const rawImages = raw.photos?.map(fixImageUrl) || [];

      return {
        id: raw.id,
        mlsId: raw.mlsId,
        resultIndex,
        imgAlt,
        images:
          rawImages?.map(url => {
            return {
              url: `${cfUrl}/image?w=500&f=webp&image=${url}`,
              set: `${cfUrl}/image?w=1000&f=webp&image=${url} 2x`,
              alt: imgAlt
            };
          }) || [],
        price: raw.listPrice,
        info: [
          raw.bedrooms !== undefined &&
            `<span class="font-bold">${raw.bedrooms}</span> ${t(
              'search.bedroomsCard',
              raw.bedrooms
            )},`,
          raw.bathsFull !== undefined &&
            `<span class="font-bold">${
              raw.bathsFull + (raw.bathsHalf || 0) / 2
            }</span>  ${t('search.bathsFullCard', {
              count: raw.bathsFull + (raw.bathsHalf || 0) / 2
            })},`,
          raw.propertyArea &&
            `<span>${t('search.areaOf')} </span><span class="font-bold">${
              raw.propertyArea
            }</span> ${t('search.areaShort')}`
        ]
          .filter(v => v)
          .join(' '),
        address,
        postalCode: t('search.postalCodeShort', [raw.postalCode]),
        to: getPropertyLink(raw, t)
      } as PropertyCardProps;
    }
  }

  function resolveClient() {
    if (typeof window !== 'undefined') {
      if (window.apolloClient?.resolveClient) {
        return window.apolloClient.resolveClient();
      }

      if (window.apolloClient?.client) {
        return window.apolloClient.client;
      }

      return window.apolloClient;
    }

    return false;
  }

  async function addPropertyToUserFavorites(
    userId: string,
    id: string,
    check = false
  ) {
    if (check) {
      const apolloClient = resolveClient();

      if (!apolloClient) {
        console.error('Error trying to add property to favorites', id);
        return;
      }

      const { GET_SAVED_PROPERTIES_BY_USER_IN } = await import(
        '@/utils/queries'
      );

      const { data } = await apolloClient.query({
        query: GET_SAVED_PROPERTIES_BY_USER_IN,
        variables: {
          property_ids: [id.toString()]
        },
        fetchPolicy: 'no-cache'
      });

      if (data.properties_saved.length > 0) {
        console.log('Property already saved');
        return true;
      }
    }

    const apolloClient = resolveClient();

    if (!apolloClient) {
      console.error('Error trying to add property to favorites', id);
      return;
    }

    const { CREATE_SAVED_PROPERTY } = await import('@/utils/queries');

    return await apolloClient.mutate({
      mutation: CREATE_SAVED_PROPERTY,
      variables: {
        user_id: userId,
        property_id: id.toString(),
        notifications: {
          email: true
        }
      }
    });
  }

  const favorites = ref<Record<string, boolean>>({});

  const notificationsEnabledProperties = ref<Record<string, boolean>>({});

  watch(isAuthenticated, (isAuthenticated, wasAuthenticated) => {
    if (!isAuthenticated && wasAuthenticated) {
      favorites.value = {};
      notificationsEnabledProperties.value = {};
    }
  });

  async function removePropertyFromUserFavorites(userId: string, id: string) {
    const apolloClient = resolveClient();

    if (!apolloClient) {
      console.error('Error trying to remove property from favorites', id);
      return;
    }

    favorites.value = {
      ...favorites.value,
      [id]: false
    };

    const { DELETE_SAVED_PROPERTY_BY_MLS_ID } = await import('@/utils/queries');

    return await apolloClient.mutate({
      mutation: DELETE_SAVED_PROPERTY_BY_MLS_ID,
      variables: {
        user_id: userId,
        property_id: id.toString()
      }
    });
  }

  async function getNotificationSettings(forceUpdate = false) {
    if (typeof window === 'undefined' || !window.userId) {
      console.error(
        'Error trying to get notification settings',
        'User is not logged in'
      );
      return;
    }
    const apolloClient = resolveClient();

    if (!apolloClient) {
      console.error('Error trying to get notification settings', 'No client');
      return;
    }

    const { GET_NOTIFICATIONS } = await import('@/utils/queries');

    const result = await apolloClient.query({
      query: GET_NOTIFICATIONS,
      variables: {
        user_id: window.userId
      },
      fetchPolicy: forceUpdate ? 'no-cache' : 'cache-first'
    });

    console.log(result);

    return Boolean(result.data.notifications?.[0]);
  }

  async function setNotificationSettings(options: { email: boolean }) {
    if (typeof window === 'undefined' || !window.userId) {
      console.error(
        'Error trying to update notification settings',
        options,
        'User is not logged in'
      );
      return;
    }

    const apolloClient = resolveClient();

    if (!apolloClient) {
      console.error('Error trying to update notification settings', options);
      return;
    }

    const { UPDATE_NOTIFICATIONS } = await import('@/utils/queries');

    return await apolloClient.mutate({
      mutation: UPDATE_NOTIFICATIONS,
      variables: {
        user_id: window.userId,
        options
      }
    });
  }

  async function updateFavorites(
    ids: string[] | null = null,
    forceUpdate = false
  ): Promise<Record<string, boolean>> {
    if (typeof window === 'undefined' || !window.userId) {
      console.error(
        'Error trying to get favorites',
        ids,
        'User is not logged in'
      );
      return {};
    }

    const apolloClient = resolveClient();

    if (!apolloClient) {
      console.error('Error trying to get favorites', 'No client');
      return {};
    }

    const { GET_SAVED_PROPERTIES_BY_USER, GET_SAVED_PROPERTIES_BY_USER_IN } =
      await import('@/utils/queries');

    const favoritesResult =
      ids === null
        ? await apolloClient.query({
            query: GET_SAVED_PROPERTIES_BY_USER,
            variables: {
              user_id: window.userId
            },
            fetchPolicy: forceUpdate ? 'no-cache' : 'cache-first'
          })
        : await apolloClient.query({
            query: GET_SAVED_PROPERTIES_BY_USER_IN,
            variables: {
              property_ids: ids.map(id => id.toString()),
              user_id: window.userId
            },
            fetchPolicy: forceUpdate ? 'no-cache' : 'cache-first'
          });

    favorites.value = favoritesResult.data.properties_saved.reduce(
      (acc: Record<string, boolean>, property: { property_id: string }) => {
        acc[property.property_id] = true;
        return acc;
      },
      favorites.value
    );

    notificationsEnabledProperties.value =
      favoritesResult.data.properties_saved.reduce(
        (
          acc: Record<string, boolean>,
          property: {
            property_id: string;
            notifications?: { email?: boolean };
          }
        ) => {
          acc[property.property_id] = property.notifications?.email;
          return acc;
        },
        notificationsEnabledProperties.value
      );

    return favorites.value;
  }

  async function setNotificationsOnProperty(
    id: string,
    enabled: boolean,
    check = false
  ) {
    if (typeof window === 'undefined' || !window.userId) {
      console.error(
        'Error trying to update favorite',
        id,
        'User is not logged in'
      );
      return;
    }

    if (check) {
      const apolloClient = resolveClient();

      if (!apolloClient) {
        console.error('Error trying to set notifications', id);
        return;
      }

      const { GET_SAVED_PROPERTIES_BY_USER_IN } = await import(
        '@/utils/queries'
      );

      const { data } = await apolloClient.query({
        query: GET_SAVED_PROPERTIES_BY_USER_IN,
        variables: {
          property_ids: [id.toString()],
          user_id: window.userId
        },
        fetchPolicy: 'no-cache'
      });

      if (data.properties_saved.length === 0) {
        console.log('Property not saved');
        return;
      }

      if (
        Boolean(data.properties_saved[0].notifications?.email) ===
        Boolean(enabled)
      ) {
        console.log('Notifications already set to value', enabled);
        return;
      }
    }

    const apolloClient = resolveClient();

    if (!apolloClient) {
      console.error('Error trying to set notifications', id);
      return;
    }

    const { UPDATE_SAVED_PROPERTY_BY_ID } = await import('@/utils/queries');

    const result = await apolloClient.mutate({
      mutation: UPDATE_SAVED_PROPERTY_BY_ID,
      variables: {
        user_id: window.userId,
        property_id: id.toString(),
        notifications: {
          email: enabled
        }
      }
    });
    await updateFavorites([id], true);

    return result;
  }

  async function setFavorite(id: string, isFavorite: boolean, check = false) {
    if (typeof window === 'undefined' || !window.userId) {
      console.error(
        'Error trying to update favorite',
        id,
        'User is not logged in'
      );
      return;
    }

    if (isFavorite) {
      await addPropertyToUserFavorites(window.userId, id, check);
    } else {
      await removePropertyFromUserFavorites(window.userId, id);
    }

    await updateFavorites([id], true);
  }

  const { triggerEvent } = useHubspot();

  const { addToast } = useToasts();

  const authModalPromise = ref<ExternalPromise<void> | null>(null);

  watch(isAuthenticated, () => {
    if (isAuthenticated.value && authModalPromise.value) {
      authModalPromise.value.resolve();
      authModalPromise.value = null;
    }
  });

  async function setPropertyAsFavorite(
    property: PropertyCardProps,
    value: boolean
  ) {
    let check = false;

    if (!isAuthenticated.value) {
      check = true;
      authModalPromise.value = new ExternalPromise<void>();
      await authModalPromise.value;
      authModalPromise.value = null;
      if (!isAuthenticated.value) {
        return;
      }
    }

    await setFavorite(property.id, value, check);

    triggerEvent('pe23281212_property_save', {
      saved: property.to
    });

    if (value) {
      addToast({
        title: t('property.propertySaved'),
        type: 'success'
      });
    } else {
      addToast({
        title: t('property.propertyRemoved'),
        type: 'info'
      });
    }
  }

  return {
    getProjectLink,
    getPropertyLink,
    getUsedProjectLink,
    formatProperty,
    getNotificationSettings,
    setNotificationSettings,
    updateFavorites,
    setNotificationsOnProperty,
    setFavorite,
    setPropertyAsFavorite,
    authModalPromise,
    favorites,
    notificationsEnabledProperties
  };
}
