import { default as indexqOnLrLMUjLMeta } from "/vercel/path0/pages/account/index.vue?macro=true";
import { default as notificationsFuA6KiWVEwMeta } from "/vercel/path0/pages/account/notifications.vue?macro=true";
import { default as profileLQWMAtlTUmMeta } from "/vercel/path0/pages/account/profile.vue?macro=true";
import { default as reset_45passwordOAVARxO1N9Meta } from "/vercel/path0/pages/account/reset-password.vue?macro=true";
import { default as saved_45propertiesaFUEzXDQvaMeta } from "/vercel/path0/pages/account/saved-properties.vue?macro=true";
import { default as saved_45searchesah37z5GcM6Meta } from "/vercel/path0/pages/account/saved-searches.vue?macro=true";
import { default as _91_46_46_46params_93SUjKB65niyMeta } from "/vercel/path0/pages/blog/[...params].vue?macro=true";
import { default as _91cityname_93JUp3opAM5PMeta } from "/vercel/path0/pages/county/[name]/city/[cityname].vue?macro=true";
import { default as indexZ8oD8G9sW2Meta } from "/vercel/path0/pages/county/[name]/index.vue?macro=true";
import { default as index2VCSLBC3QvMeta } from "/vercel/path0/pages/county/index.vue?macro=true";
import { default as indexp7pbTliZK5Meta } from "/vercel/path0/pages/elite/index.vue?macro=true";
import { default as infol2HALctwoOMeta } from "/vercel/path0/pages/elite/info.vue?macro=true";
import { default as reset_45password89XX9JwgZLMeta } from "/vercel/path0/pages/elite/reset-password.vue?macro=true";
import { default as thank_45youeyUTsVnmrJMeta } from "/vercel/path0/pages/elite/thank-you.vue?macro=true";
import { default as _91_46_46_46params_93mKb1TEKG2yMeta } from "/vercel/path0/pages/homes-and-condos/[...params].vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91_46_46_46params_93Y6BfoeHSoAMeta } from "/vercel/path0/pages/market-report/[...params].vue?macro=true";
import { default as indexqzj4dvpmvWMeta } from "/vercel/path0/pages/mortgage-calculator/index.vue?macro=true";
import { default as _91_46_46_46params_93TMBV8l18RgMeta } from "/vercel/path0/pages/new-projects/[...params].vue?macro=true";
import { default as _91_46_46_46params_93K5AOY44DAHMeta } from "/vercel/path0/pages/pfs/[...params].vue?macro=true";
import { default as indexRR6HewsL0MMeta } from "/vercel/path0/pages/test-page/index.vue?macro=true";
export default [
  {
    name: "account___es",
    path: "/cuenta",
    meta: indexqOnLrLMUjLMeta || {},
    component: () => import("/vercel/path0/pages/account/index.vue")
  },
  {
    name: "account___us",
    path: "/us/account",
    meta: indexqOnLrLMUjLMeta || {},
    component: () => import("/vercel/path0/pages/account/index.vue")
  },
  {
    name: "account-notifications___es",
    path: "/cuenta/notificaciones",
    component: () => import("/vercel/path0/pages/account/notifications.vue")
  },
  {
    name: "account-notifications___us",
    path: "/us/account/notifications",
    component: () => import("/vercel/path0/pages/account/notifications.vue")
  },
  {
    name: "account-profile___es",
    path: "/cuenta/perfil",
    component: () => import("/vercel/path0/pages/account/profile.vue")
  },
  {
    name: "account-profile___us",
    path: "/us/account/profile",
    component: () => import("/vercel/path0/pages/account/profile.vue")
  },
  {
    name: "account-reset-password___es",
    path: "/cuenta/reiniciar-contrasena",
    component: () => import("/vercel/path0/pages/account/reset-password.vue")
  },
  {
    name: "account-reset-password___us",
    path: "/us/account/reset-password",
    component: () => import("/vercel/path0/pages/account/reset-password.vue")
  },
  {
    name: "account-saved-properties___es",
    path: "/cuenta/propiedades-guardadas",
    meta: saved_45propertiesaFUEzXDQvaMeta || {},
    component: () => import("/vercel/path0/pages/account/saved-properties.vue")
  },
  {
    name: "account-saved-properties___us",
    path: "/us/account/saved-properties",
    meta: saved_45propertiesaFUEzXDQvaMeta || {},
    component: () => import("/vercel/path0/pages/account/saved-properties.vue")
  },
  {
    name: "account-saved-searches___es",
    path: "/cuenta/busquedas-guardadas",
    meta: saved_45searchesah37z5GcM6Meta || {},
    component: () => import("/vercel/path0/pages/account/saved-searches.vue")
  },
  {
    name: "account-saved-searches___us",
    path: "/us/account/saved-searches",
    meta: saved_45searchesah37z5GcM6Meta || {},
    component: () => import("/vercel/path0/pages/account/saved-searches.vue")
  },
  {
    name: "blog-params___es",
    path: "/blog/:params(.*)*",
    component: () => import("/vercel/path0/pages/blog/[...params].vue")
  },
  {
    name: "blog-params___us",
    path: "/us/blog/:params(.*)*",
    component: () => import("/vercel/path0/pages/blog/[...params].vue")
  },
  {
    name: "county-name-city-cityname___es",
    path: "/county/:name()/city/:cityname()",
    component: () => import("/vercel/path0/pages/county/[name]/city/[cityname].vue")
  },
  {
    name: "county-name-city-cityname___us",
    path: "/us/county/:name()/city/:cityname()",
    component: () => import("/vercel/path0/pages/county/[name]/city/[cityname].vue")
  },
  {
    name: "county-name___es",
    path: "/condado/:name()",
    component: () => import("/vercel/path0/pages/county/[name]/index.vue")
  },
  {
    name: "county-name___us",
    path: "/us/county/:name()",
    component: () => import("/vercel/path0/pages/county/[name]/index.vue")
  },
  {
    name: "county___es",
    path: "/condado",
    component: () => import("/vercel/path0/pages/county/index.vue")
  },
  {
    name: "county___us",
    path: "/us/county",
    component: () => import("/vercel/path0/pages/county/index.vue")
  },
  {
    name: "elite___es",
    path: "/elite",
    component: () => import("/vercel/path0/pages/elite/index.vue")
  },
  {
    name: "elite___us",
    path: "/us/elite",
    component: () => import("/vercel/path0/pages/elite/index.vue")
  },
  {
    name: "elite-info___es",
    path: "/elite/info",
    component: () => import("/vercel/path0/pages/elite/info.vue")
  },
  {
    name: "elite-info___us",
    path: "/us/elite/info",
    component: () => import("/vercel/path0/pages/elite/info.vue")
  },
  {
    name: "elite-reset-password___es",
    path: "/elite/reiniciar-contrasena",
    component: () => import("/vercel/path0/pages/elite/reset-password.vue")
  },
  {
    name: "elite-reset-password___us",
    path: "/us/elite/reset-password",
    component: () => import("/vercel/path0/pages/elite/reset-password.vue")
  },
  {
    name: "elite-thank-you___es",
    path: "/elite/gracias",
    component: () => import("/vercel/path0/pages/elite/thank-you.vue")
  },
  {
    name: "elite-thank-you___us",
    path: "/us/elite/thank-you",
    component: () => import("/vercel/path0/pages/elite/thank-you.vue")
  },
  {
    name: "homes-and-condos-params___es",
    path: "/casas-y-apartamentos/:params(.*)*",
    component: () => import("/vercel/path0/pages/homes-and-condos/[...params].vue")
  },
  {
    name: "homes-and-condos-params___us",
    path: "/us/homes-and-condos/:params(.*)*",
    component: () => import("/vercel/path0/pages/homes-and-condos/[...params].vue")
  },
  {
    name: "index___es",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "index___us",
    path: "/us",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "market-report-params___es",
    path: "/reporte-de-mercado/:params(.*)*",
    component: () => import("/vercel/path0/pages/market-report/[...params].vue")
  },
  {
    name: "market-report-params___us",
    path: "/us/market-report/:params(.*)*",
    component: () => import("/vercel/path0/pages/market-report/[...params].vue")
  },
  {
    name: "mortgage-calculator___es",
    path: "/calculadora-de-hipotecas",
    component: () => import("/vercel/path0/pages/mortgage-calculator/index.vue")
  },
  {
    name: "mortgage-calculator___us",
    path: "/us/mortgage-calculator",
    component: () => import("/vercel/path0/pages/mortgage-calculator/index.vue")
  },
  {
    name: "new-projects-params___es",
    path: "/nuevos-proyectos/:params(.*)*",
    component: () => import("/vercel/path0/pages/new-projects/[...params].vue")
  },
  {
    name: "new-projects-params___us",
    path: "/us/new-projects/:params(.*)*",
    component: () => import("/vercel/path0/pages/new-projects/[...params].vue")
  },
  {
    name: "pfs-params___es",
    path: "/pfs/:params(.*)*",
    component: () => import("/vercel/path0/pages/pfs/[...params].vue")
  },
  {
    name: "pfs-params___us",
    path: "/us/pfs/:params(.*)*",
    component: () => import("/vercel/path0/pages/pfs/[...params].vue")
  },
  {
    name: "test-page___es",
    path: "/test-page",
    component: () => import("/vercel/path0/pages/test-page/index.vue")
  },
  {
    name: "test-page___us",
    path: "/us/test-page",
    component: () => import("/vercel/path0/pages/test-page/index.vue")
  }
]